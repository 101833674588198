import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'

const BorderLinearProgress = withStyles(theme => ({
  colorPrimary: {
    backgroundColor: '#E0E0E0',
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary.green,
  },
}))(LinearProgress)

export default BorderLinearProgress
