import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import BorderLinearProgress from '../atoms/BorderLinearProgress'

import { useIntl } from 'react-intl'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  CourseProgress: {},
  CourseProgress_MainContainer: {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
    padding: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(1),
    },
  },
  CourseProgress_Title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down(768)]: {
      textTransform: 'uppercase',
      fontSize: '14px',
      lineHeight: '19px',
      marginBottom: '10px',
      opacity: '0.87',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  CourseProgress_ProgressStrings: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1600)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down(960)]: {
      flexDirection: 'row',
    },
  },
  CourseProgress_Text: {
    marginRight: '5px',
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.6',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
  CourseProgress_BorderLinearProgress: {
    marginBottom: theme.spacing(2),
  },
  CourseProgress_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const CourseProgress = ({
  progress = 0,
  title = '',
  timeProgressString = '',
  progressStrings,
}) => {
  const c = useStyles()
  const intl = useIntl()

  return (
    <>
      <Paper className={c.CourseProgress_MainContainer}>
        <Typography className={clsx(c.CourseProgress_Title, c.CourseProgress_OneLine)}>
          {title}
        </Typography>
        <Typography className={c.CourseProgress_Text}>{`${(progress * 100).toFixed(
          2
        )}% ${intl.formatMessage({
          id: 'shared.completed',
        })}`}</Typography>
        <Typography className={c.CourseProgress_Text}>{timeProgressString}</Typography>
        <div className={c.CourseProgress_ProgressStrings}>
          {progressStrings.map(progressString => (
            <Typography key={progressString} className={c.CourseProgress_Text}>
              {progressString}
            </Typography>
          ))}
        </div>
      </Paper>
      <BorderLinearProgress
        className={c.CourseProgress_BorderLinearProgress}
        variant='determinate'
        value={progress * 100}
      />
    </>
  )
}

export default CourseProgress
