import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DoneIcon from '@material-ui/icons/Done'

import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  DoneButton: {
    color: '#707070',
    borderColor: '#707070',
    height: theme.spacing(6),
    fontSize: '16px',
    lineHeight: '21px',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '19px',
      height: theme.spacing(4),
    },
  },
}))

const DoneButton = ({ onClick = () => {} }) => {
  const c = useStyles()

  return (
    <Button
      variant='outlined'
      color='secondary'
      className={c.DoneButton}
      startIcon={<DoneIcon />}
      onClick={onClick}
    >
      <FormattedMessage id='shared.done' />
    </Button>
  )
}

export default DoneButton
