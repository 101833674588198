import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import BreadCramps from '../atoms/BreadCramps'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import BorderLinearProgress from '../atoms/BorderLinearProgress'
import { FormattedMessage, useIntl } from 'react-intl'

import DOMPurify from 'dompurify'

const useStyles = makeStyles(theme => ({
  SideHeader: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(600)]: {
      marginBottom: theme.spacing(2),
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  SideHeader__SpaceBetween: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  SideHeader_HomeButtonWrapper: {
    flexShrink: '0',
  },
  SideHeader_HomeButton: {
    height: theme.spacing(6),
    [theme.breakpoints.down(768)]: {
      fontSize: '13px',
      lineHeight: '13px',
      height: theme.spacing(5),
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '0',
      width: '35px',
      minWidth: '35px',
      height: '35px',
      padding: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '20px',
      '& .MuiButton-startIcon': {
        margin: '0',
      },
    },
  },
  SideHeader_TypeOfInfo: {
    fontSize: '14px',
    lineHeight: '19px',
    opacity: '0.87',
    marginBottom: 4,
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  SideHeader_Title: {
    fontSize: '48px',
    lineHeight: '63px',
    marginBottom: '20px',
    [theme.breakpoints.down(768)]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  SideHeader_Text: {
    marginTop: theme.spacing(4),
    fontSize: '20px',
    lineHeight: '26px',
    marginBottom: theme.spacing(3),
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    '& iframe': {
      maxWidth: '100%',
      height: '600px',
      [theme.breakpoints.down(1300)]: {
        height: '340px',
      },
      [theme.breakpoints.down(960)]: {
        height: '440px',
      },
      [theme.breakpoints.down(800)]: {
        height: '360px',
      },
      [theme.breakpoints.down(600)]: {
        height: '270px',
      },
      [theme.breakpoints.down(400)]: {
        height: '220px',
      },
    },
    [theme.breakpoints.down(768)]: {
      fontSize: '18px',
      lineHeight: '24px',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '16px',
      lineHeight: '18px',
    },
  },
  SideHeader_BorderLinearProgress: {
    marginBottom: '4px',
  },
  SideHeader_BorderProgressText: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.87',
    marginBottom: theme.spacing(1),
  },
  SideHeader_ProgressContainer: {
    textAlign: 'right',
    marginBottom: '20px',
    marginTop: '-20px',
  },
  SideHeader_MobileCards: {
    display: 'none',
    [theme.breakpoints.down(960)]: {
      display: 'block',
    },
  }
}))

const SideHeader = ({
  title = '',
  introduction = '',
  type = '',
  breadCrampsLinks = {},
  progress = 0,
  showProgress = false,
  mobileCards,
  doneBtn,
}) => {
  const history = useHistory()
  const c = useStyles()
  const intl = useIntl()

  useEffect(() => {
    const h5pResizer = document.createElement('script')
    h5pResizer.src =
      'https://kurse.plakos-akademie.de/wp-content/plugins/h5p/h5p-php-library/js/h5p-resizer.js'
    h5pResizer.async = true
    document.body.appendChild(h5pResizer)

    const script = document.createElement('script')
    script.src = 'https://www.plakos.de/buttoncode.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(h5pResizer)
    }
  }, [])

  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'iframe') {
      const sandbox = node.getAttribute('sandbox') || ''
      if (!sandbox) {
        return node.parentNode?.removeChild(node)
      }
    }
  })

  return (
    <>
      <Grid
        container
        alignItems='center'
        className={clsx(c.SideHeader, breadCrampsLinks && c.SideHeader__SpaceBetween)}
      >
        <Grid item>
          <BreadCramps breadCrampsLinks={breadCrampsLinks} />
        </Grid>
        <Grid item className={c.SideHeader_HomeButtonWrapper}>
          {doneBtn && doneBtn}
        </Grid>
      </Grid>
      <Typography className={c.SideHeader_TypeOfInfo} component='p'>
        {type}
      </Typography>
      <Typography className={c.SideHeader_Title} component='h2'>
        {title}
      </Typography>
      {showProgress && (
        <Box className={c.SideHeader_ProgressContainer}>
          <BorderLinearProgress
            className={c.SideHeader_BorderLinearProgress}
            variant='determinate'
            value={progress * 100}
          />
          <Typography className={c.SideHeader_BorderProgressText}>
            {`${progress * 100}% ${intl.formatMessage({ id: 'shared.correct' })}`}
          </Typography>
        </Box>
      )}
      {mobileCards && <Grid className={c.SideHeader_MobileCards}>{mobileCards}</Grid>}
      {introduction !== '' && (
        <Typography className={c.SideHeader_Text} component='div'>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(introduction, {
                ADD_TAGS: ['iframe'],
                ADD_ATTR: [
                  'allow',
                  'allowfullscreen',
                  'frameborder',
                  'scrolling',
                  'sandbox',
                ],
              }),
            }}
          />
        </Typography>
      )}
    </>
  )
}

export default SideHeader
