import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import logo from '@assets/logo_iu.svg'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SearchIcon from '@material-ui/icons/Search'
import SchoolIcon from '@material-ui/icons/School'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

import SearchInput from './Search/SearchInput'
import { useDispatch } from 'react-redux'
import { logout } from '@pages/SSOLogin/slice'
import { useIntl } from 'react-intl'
import { CardActionArea } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  Header_AppBar: {
    height: 64,
    display: 'flex',
    justifyContent: 'center',
    top: 0,
    bottom: 'auto',
    background: `${theme.palette.header.background} 0% 0% no-repeat padding-box`,
    boxShadow: `0px 3px 6px ${theme.palette.shadow.default}`,
    '-webkit-box-shadow': `0px 3px 6px ${theme.palette.shadow.default}`,
  },
  Header_AppBar__Logged: {
    background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
    boxShadow: 'none',
    '-webkit-box-shadow': 'none',
  },
  Header_MainContainer: {
    width: '1852px',
    margin: '0 auto 0 0',
  },
  Header_RightContainer: {
    textAlign: 'right',
  },
  Header_Toolbar: {
    margin: '0 32px 0 32px',
    padding: '0',
    borderBottom: 'none',
    [theme.breakpoints.down(768)]: {
      margin: '0 16px 0 16px',
    },
  },
  Header_Toolbar__Logged: {
    borderBottom: `1px solid ${theme.palette.primary.mainTransparent}`,
  },
  Header_SearchContainer: {
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Header_IconButton: {
    padding: '17px',
    color: theme.palette.secondary.gray,
    [theme.breakpoints.down(500)]: {
      padding: '13px 13px 13px 7px',
    },
  },
  Header_IconButton__Search: {
    display: 'none',
    [theme.breakpoints.down(960)]: {
      display: 'inline-flex',
    },
  },
  Header_IconButton__Active: {
    color: theme.palette.primary.mainTransparent,
  },
  Header_Icon: {
    fontSize: '32px',
    zIndex: '-1',
    [theme.breakpoints.down(500)]: {
      fontSize: '28px',
    },
  },
  Header_LeftContainer: {
    textAlign: 'left',
    height: '50px',
    display: 'flex',
    margin: 'auto',
    position: 'relative',
  },
  Header_Title: {
    fontSize: '24px',
    margin: 'auto 24px auto 0',
    zIndex: '-1',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Header_Logo: {
    margin: '0 24px 0 0',
    cursor: 'pointer',
    width: '140px',
    height: '50px',
  },
  Header_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  Header_Title_Container: {
    maxWidth: '250px',
  },
}))

const Header = () => {
  const history = useHistory()
  const pathname = useLocation().pathname
  const dispatch = useDispatch()
  const intl = useIntl()

  const c = useStyles()

  const [headerTitle, setHeaderTitle] = useState(
    intl.formatMessage({ id: 'shared.myCourses' })
  )
  const [isSearchShow, setIsSearchShow] = useState(true)
  const [isButtonShow, setIsButtonShow] = useState(true)
  const [path, setPath] = useState('/')

  useEffect(() => {
    handleHeaderElements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  const handleHeaderElements = () => {
    setIsSearchShow(false)
    setIsButtonShow(true)
    switch (history?.location?.pathname) {
      case '/community':
        setHeaderTitle('Community')
        break
      case '/profile':
        setHeaderTitle(intl.formatMessage({ id: 'shared.myProfile' }))
        break
      case '/login':
        setHeaderTitle('')
        setIsButtonShow(false)
        break
      case '/token':
        setHeaderTitle('')
        setIsButtonShow(false)
        break
      default:
        setHeaderTitle(intl.formatMessage({ id: 'shared.myCourses' }))
        setIsSearchShow(true)
    }
  }

  const handleRedirect = path => {
    setPath(path)
    history.push(path)
  }

  const alternativeHeader = pathname !== '/login' && pathname !== '/token'
  return (
    <AppBar
      position='fixed'
      className={clsx(c.Header_AppBar, alternativeHeader && c.Header_AppBar__Logged)}
    >
      <Toolbar
        className={clsx(c.Header_Toolbar, alternativeHeader && c.Header_Toolbar__Logged)}
      >
        <Box className={c.Header_MainContainer}>
          <Grid container wrap='nowrap'>
            <Grid className={c.Header_LeftContainer} item xs={3} md={8}>
              <Tooltip title={intl.formatMessage({ id: 'shared.toDashboard' })}>
                <img
                  src={logo}
                  alt='IU logo'
                  className={c.Header_Logo}
                  onClick={() => {
                    handleRedirect('/')
                  }}
                />
              </Tooltip>
              <CardActionArea
                className={c.Header_Title_Container}
                disableRipple={true}
                onClick={() => {
                  handleRedirect('/')
                }}
              >
                <Typography className={clsx(c.Header_Title, c.Header_OneLine)}>
                  {headerTitle}
                </Typography>
              </CardActionArea>
              {isSearchShow && (
                <Box className={c.Header_SearchContainer}>
                  <SearchInput />
                </Box>
              )}
            </Grid>
            <Grid className={c.Header_RightContainer} item xs={9} md={4}>
              {isButtonShow && (
                <Box>
                  <IconButton
                    className={clsx(
                      c.Header_IconButton,
                      c.Header_IconButton__Search,
                      pathname === '/search' && c.Header_IconButton__Active
                    )}
                    onClick={() => handleRedirect('/search')}
                  >
                    <SearchIcon className={c.Header_Icon} />
                  </IconButton>
                  <Tooltip title={intl.formatMessage({ id: 'shared.toDashboard' })}>
                    <IconButton
                      className={clsx(
                        c.Header_IconButton,
                        pathname === '/' && c.Header_IconButton__Active
                      )}
                      onClick={() => handleRedirect('/dashboard')}
                    >
                      <SchoolIcon className={c.Header_Icon} />
                    </IconButton>
                  </Tooltip>
                  {/* <IconButton
                    className={clsx(
                      c.Header_IconButton,
                      pathname === '/profile' && c.Header_IconButton__Active
                    )}
                    onClick={() => handleRedirect('/profile')}
                  >
                    <AccountCircleIcon className={c.Header_Icon} />
                  </IconButton> */}
                  <Tooltip title={intl.formatMessage({ id: 'login.logout' })}>
                    <IconButton
                      className={c.Header_IconButton}
                      onClick={() => dispatch(logout())}
                    >
                      <ExitToAppIcon className={c.Header_Icon} />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
