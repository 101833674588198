import React from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import CardActionArea from '@material-ui/core/CardActionArea'
import Box from '@material-ui/core/Box'
import Avatar from 'react-avatar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose'
import Tooltip from '@material-ui/core/Tooltip'

import BorderLinearProgress from '@components/atoms/BorderLinearProgress'

import { FormattedMessage } from 'react-intl'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  BlockWithIcon: {
    margin: '10px 16px',
  },
  BlockWithIcon_Icon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  BlockWithIcon_Title: {
    fontSize: '21px',
    lineHeight: '28px',
    height: '28px',
    [theme.breakpoints.down(550)]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  BlockWithIcon_Text: {
    fontSize: '12px',
    lineHeight: '16px',
    height: '32px',
    opacity: '0.6',
    paddingBottom: '8px',
    marginBottom: '14px',
  },
  BlockWithIcon_AvatarWrapper: {
    width: '114px',
    height: '114px',
    fontSize: '40px',
    flexShrink: '0',
    padding: 0,
    [theme.breakpoints.down(550)]: {
      width: '66px',
      height: '66px',
      fontSize: '25px',
    },
  },
  BlockWithIcon_TextTooltip: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  BlockWithIcon_ButtonContainer: {
    width: '100%',
    textAlign: 'right',
  },
  BlockWithIcon_Button: {
    paddingRight: 0,
    fontSize: '17px',
    lineHeight: '23px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    textTransform: 'uppercase',
    [theme.breakpoints.down(550)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  BlockWithIcon_LinearProgress: {
    marginBottom: '8px',
  },
  BlockWithIcon_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BlockWithIcon_IconContainer: {
    width: '100%',
  },
  BlockWithIcon_LinesEllipsis: {
    wordBreak: 'break-all',
  },
}))

export default function BlockWithIcon({
  icon = '',
  title = '',
  progress = 0,
  text = '',
  id,
}) {
  const c = useStyles()
  const history = useHistory()

  return (
    <CardActionArea
      onClick={() => {
        setTimeout(() => {
          history.push(`/course/${id}`)
        }, 700)
      }}
    >
      <Box className={c.BlockWithIcon}>
        <Grid container spacing={2} wrap='nowrap'>
          <Grid item className={c.BlockWithIcon_AvatarWrapper}>
            <Avatar
              className={c.BlockWithIcon_Icon}
              src={icon}
              color={'#3772ff'}
              size='100%'
              round={true}
              title={title}
              name={title.replace(/[^a-zA-Z\s]+/g, '')}
              maxInitials={2}
            />
          </Grid>
          <Grid item className={c.BlockWithIcon_IconContainer}>
            <div>
              <Typography className={clsx(c.BlockWithIcon_Title)} component='div'>
                <LinesEllipsisLoose
                  text={title}
                  maxLine='1'
                  lineHeight='28'
                  className={c.BlockWithIcon_LinesEllipsis}
                />
              </Typography>
            </div>
            <Tooltip title={text} classes={{ tooltip: c.BlockWithIcon_TextTooltip }}>
              <Typography className={clsx(c.BlockWithIcon_Text)} component='div'>
                <LinesEllipsisLoose
                  text={text}
                  style={{ wordBreak: 'normal' }}
                  maxLine='2'
                  lineHeight='16'
                  className={c.BlockWithIcon_LinesEllipsis}
                />
              </Typography>
            </Tooltip>
            <Grid className={c.BlockWithIcon_ButtonContainer} item>
              <Typography className={c.BlockWithIcon_Button} color='primary'>
                <FormattedMessage id='shared.view' />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <BorderLinearProgress
        className={c.RightSideNavigationCard_BorderLinearProgress}
        variant='determinate'
        value={progress * 100}
      />
    </CardActionArea>
  )
}
