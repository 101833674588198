import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCourse, selectCourse, selectCourseStatus } from './slice'

import { useParams, useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import LessonItem from './Cards/LessonItem'
import CourseProgress from '@components/molecules/CourseProgress'
import NavigationCard from '@components/molecules/NavigationCard'
import LayoutWithImage from '@components/molecules/LayoutWithImage'
import SideHeader from '@components/molecules/SideHeader'

import { FormattedMessage, useIntl } from 'react-intl'
import CourseProgressSummary from '../../@components/molecules/CourseProgressSummary'
import StartButton from '../../@components/atoms/StartButton'
import IframeResizer from 'iframe-resizer-react'
import NotepadCard from '@components/molecules/NotepadCard'
import TestCompletionBanner from '../../@components/atoms/TestCompletionBanner'
import SecureFenIntegration from '@components/molecules/SecureFENIntegration'

const useStyles = makeStyles(theme => ({
  LeftSideCourse: {},
  LeftSideCourse_SubTitle: {
    fontSize: '34px',
    lineHeight: '45px',
    marginBottom: '19px',
    [theme.breakpoints.down(768)]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '20px',
      lineHeight: '26px',
      marginBottom: '11px',
    },
  },
  RightSideCourse_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Test_Iframe: {
    minWidth: '100%',
    border: 0,
  },
  Test_IframeC: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down(960)]: {
      marginRight: '0',
      marginLeft: '0',
    },
  },
  Button_C: {
    marginBottom: '16px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}))

const Cards = ({ course }) => {
  const {
    id,
    progress,
    last_test = '',
    next_test = '',
    progressData,
    renderDefault,
    title,
    notepad,
    postImage,
  } = course
  const intl = useIntl()

  const history = useHistory()

  const textForTest = test => {
    return (
      (test?.surroundingLesson?.title ? test.surroundingLesson.title : '') +
      (test?.surroundingTopic?.title ? ` - ${test.surroundingTopic.title}` : '')
    )
  }

  const getTimeProgressString = () => {
    let result = ''
    if (progressData) {
      const { amountMinutesDone, amountHoursDone, amountHours } = progressData
      if (typeof amountHoursDone === 'number' && typeof amountMinutesDone === 'number') {
        result += amountHoursDone + 'h '
        if (amountMinutesDone > 0) {
          result += amountMinutesDone + 'm '
        }
        result += '/ ' + amountHours + 'h'
      }
    }
    return result
  }

  const getProgressStrings = () => {
    const result = []

    if (progressData) {
      const {
        amountLessons,
        amountLessonsDone,
        amountTests,
        amountTopics,
        amountTestsDone,
        amountTopicsDone,
      } = progressData

      if (
        typeof amountLessons === 'number' &&
        typeof amountLessonsDone === 'number' &&
        amountLessons
      ) {
        result.push(`
        ${amountLessonsDone}/${amountLessons} ${intl.formatMessage({
  id: 'shared.lessons',
        })}`)
      }

      if (
        typeof amountTopics === 'number' &&
        typeof amountTopicsDone === 'number' &&
        amountTopics
      ) {
        result.push(`
        ${amountTopicsDone}/${amountTopics} ${intl.formatMessage({
          id: 'shared.topics',
        })}`)
      }

      if (
        typeof amountTests === 'number' &&
        typeof amountTestsDone === 'number' &&
        amountTests
      ) {
        result.push(`
        ${amountTestsDone}/${amountTests} ${intl.formatMessage({
  id: 'shared.tests',
        })}`)
      }
    }

    return result
  }

  const notepadAction = () => {
    history.push(`/notepad/${id}`, {
      courseId: id,
      courseTitle: title,
    })
  }

  return (
    <>
      {renderDefault && (
        <CourseProgress
          title={intl.formatMessage({ id: 'shared.progress' })}
          progress={progress}
          timeProgressString={getTimeProgressString()}
          progressStrings={getProgressStrings()}
        />
      )}
      {!renderDefault && (
        <CourseProgressSummary
          title={intl.formatMessage({ id: 'shared.progress' })}
          courseTitle={title}
          progressData={progressData}
          progress={progress}
        />
      )}
      {next_test && renderDefault && (
        <NavigationCard
          typeOfTest={intl.formatMessage({ id: 'shared.nextTest' })}
          title={next_test?.title}
          text={textForTest(next_test)}
          progress={next_test?.progress}
          buttonText={intl.formatMessage({ id: 'shared.start' })}
          buttonLink={`/test/${id}/${next_test?.id}`}
        />
      )}
      {last_test && renderDefault && (
        <NavigationCard
          typeOfTest={intl.formatMessage({ id: 'shared.previousTest' })}
          title={last_test?.title}
          text={textForTest(last_test)}
          progress={last_test?.progress}
          buttonText={intl.formatMessage({ id: 'shared.start' })}
          buttonLink={`/test/${id}/${last_test?.id}`}
        />
      )}
      {notepad && <NotepadCard notepad={notepad} handleAction={notepadAction} />}
    </>
  )
}

const LeftSideCourse = ({ course }) => {
  const [isTestStarted, setIsTestStarted] = useState(false)
  const intl = useIntl()
  const c = useStyles()
  const {
    id,
    title,
    introduction,
    lessons = [],
    renderDefault,
    userIsEnrolled,
    testLink,
    progress,
  } = course

  const hadleTestButton = () => {
    setIsTestStarted(prevState => !prevState)
  }

  return (
    <>
      <SideHeader
        title={title}
        introduction={introduction}
        type={intl.formatMessage({ id: 'shared.course' })}
        mobileCards={<Cards course={course} />}
        progress={progress}
        renderDefault={renderDefault}
      />
      {!renderDefault && progress >= 1 && (
        <TestCompletionBanner title={title}></TestCompletionBanner>
      )}
      {!renderDefault && (
        <div className={c.Button_C}>
          <StartButton onClick={hadleTestButton} disabled={!userIsEnrolled} />
        </div>
      )}

      {!renderDefault && isTestStarted ? (
        <SecureFenIntegration src={testLink}></SecureFenIntegration>
      ) : (
        <></>
      )}
      {lessons.length > 0 && (
        <Typography className={c.LeftSideCourse_SubTitle} component='h3'>
          <FormattedMessage id='shared.lessons' />
        </Typography>
      )}
      {lessons.map(lesson => (
        <LessonItem
          key={lesson?.id}
          title={lesson?.title}
          progress={lesson?.progress}
          amountTopics={lesson?.amountTopics}
          amountTests={lesson?.amountTests}
          buttonLink={`/lesson/${id}/${lesson?.id}`}
        />
      ))}
    </>
  )
}

const Course = () => {
  const dispatch = useDispatch()
  const { courseId } = useParams()
  const c = useStyles()

  useEffect(() => {
    dispatch(getCourse(courseId))
  }, [dispatch, courseId])

  const course = useSelector(selectCourse)
  const courseStatus = useSelector(selectCourseStatus)

  return (
    <LayoutWithImage status={courseStatus} postImage={course?.postImage}>
      {course && (
        <>
          <Grid item xs={12} md={9}>
            <LeftSideCourse course={course} />
          </Grid>
          <Grid item xs={12} md={3} className={c.RightSideCourse_DesktopCards}>
            <Cards course={course} />
          </Grid>
        </>
      )}
    </LayoutWithImage>
  )
}

export default Course
