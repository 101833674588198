import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormattedMessage } from 'react-intl'
import { STATUSES } from '@constants/slices'
import ScrollToTopButton from '@components/atoms/ScrollToTopButton'

const useStyles = makeStyles(theme => ({
  LayoutWithImage: {
    [theme.breakpoints.down(600)]: {
      margin: '0 -16px',
      width: 'calc(100% + 32px)',
    },
  },
  LayoutWithImage_BackgroundImage: {
    position: 'absolute',
    top: 64,
    left: 0,
    right: 0,
    objectFit: 'cover',
    width: '100%',
    height: '430px',
    zIndex: -1,
    [theme.breakpoints.down(768)]: {
      height: '270px',
    },
    [theme.breakpoints.down(600)]: {
      height: '200px',
    },
  },
  LayoutWithImage_MainWrapper: {
    width: '100%',
    [theme.breakpoints.down(600)]: {
      margin: '0 -16px',
    },
  },
  LayoutWithImage_InformationCard: {
    marginTop: '438px',
    height: '100%',
    borderRadius: '24px 24px 0px 0px',
    padding: theme.spacing(6),
    minHeight: '700px',
    [theme.breakpoints.down(768)]: {
      marginTop: '278px',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '180px',
      padding: theme.spacing(2),
    },
  },
  LayoutWithImage_ContentWrapper: {
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down(600)]: {
      margin: '-16px',
      width: 'calc(100% + 32px)',
    },
  },
  LayoutWithImage_StatusContainer: {
    height: '100vh',
  },
}))

const LayoutWithImage = ({ children, postImage = '', status }) => {
  const c = useStyles()

  return (
    <>
      {status === STATUSES.LOADING && (
        <Grid
          className={c.LayoutWithImage_StatusContainer}
          container
          justify='center'
          alignItems='center'
        >
          <CircularProgress />
        </Grid>
      )}
      {status === STATUSES.ERROR && (
        <Grid
          className={c.LayoutWithImage_StatusContainer}
          container
          justify='center'
          alignItems='center'
        >
          <Typography component='p'>
            <FormattedMessage id='shared.somethingWrong' />
          </Typography>
        </Grid>
      )}
      {status === STATUSES.LOADED && (
        <Grid
          container
          justify='center'
          alignItems='flex-start'
          className={c.LayoutWithImage}
        >
          {postImage && (
            <img
              className={c.LayoutWithImage_BackgroundImage}
              alt='background image'
              src={postImage}
            ></img>
          )}
          <Grid item className={c.LayoutWithImage_MainWrapper}>
            <Paper className={c.LayoutWithImage_InformationCard}>
              <Grid container spacing={6} className={c.LayoutWithImage_ContentWrapper}>
                {children}
              </Grid>
            </Paper>
          </Grid>
          <ScrollToTopButton></ScrollToTopButton>
        </Grid>
      )}
    </>
  )
}

export default LayoutWithImage
