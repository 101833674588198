import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboard, selectDashboard, selectDashboardStatus } from './slice'

import CardWithImage from '@components/molecules/СardWithImage'
import CardCourses from '@components/molecules/CardCourses'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Paper, Typography } from '@material-ui/core'

import { FormattedMessage, useIntl } from 'react-intl'

const myStyles = makeStyles(theme => ({
  DashBoard: {},
  DashBoard_Header: {
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
    },
  },
  DashBoard_CoursesWrapper: {
    paddingRight: '16px',
    [theme.breakpoints.down(960)]: {
      paddingRight: '0',
    },
  },
  Card_Paper__blank: {
    padding: 15,
    backgroundColor: '#fff',
    height: 200,
    marginBottom: theme.spacing(2),
  },
  DashBoard_MainTitle: {
    fontSize: '48px',
    lineHeight: '63px',
    padding: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(550)]: {
      fontSize: '32px',
      padding: `${theme.spacing(1)}px 0`,
    },
  },
  DashBoard_SubTitle: {
    fontSize: '21px',
    lineHeight: '36px',
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down(550)]: {
      fontSize: '17px',
      padding: `${theme.spacing(1)}px 0`,
    },
  },
  MaintenanceBanner: {
    padding: '12px 12px 12px 12px',
    margin: '16px 0px 16px 0px',
    backgroundColor: '#BCEBCB',
  },
  MaintenanceHeader: {
    fontSize: '24px',
    [theme.breakpoints.down(768)]: {
      fontSize: '20px',
    },
  },
  MaintenanceContent: {
    fontSize: '18px',
    [theme.breakpoints.down(768)]: {
      fontSize: '15px',
      lineHeight: '24px',
    },
  },
}))

const MaintenanceBanner = () => {
  const intl = useIntl()
  const c = myStyles()
  return (
    <Paper elevation={0} className={c.MaintenanceBanner}>
      <Typography className={c.MaintenanceHeader}>
        {intl.formatMessage({ id: 'maintenance.header' })}
      </Typography>
      <Typography className={c.MaintenanceContent}>
        {intl.formatMessage({ id: 'maintenance.content' })}
      </Typography>
    </Paper>
  )
}

const textForLastTest = dashboard => {
  if (dashboard.last_test) {
    const { surroundingCourse, surroundingLesson, surroundingTopic } = dashboard.last_test
    return [surroundingCourse, surroundingLesson, surroundingTopic]
      .map(item => item?.title)
      .filter(item => !!item)
      .join(' - ')
  }
  return ''
}

const textForLastTopic = dashboard => {
  if (dashboard.last_topic) {
    const { surroundingCourse, surroundingLesson } = dashboard.last_topic

    return [surroundingCourse, surroundingLesson]
      .map(item => item?.title)
      .filter(item => !!item)
      .join(' - ')
  }
  return ''
}

const DashBoard = () => {
  const dispatch = useDispatch()
  const c = myStyles()
  const intl = useIntl()
  const dashboard = useSelector(selectDashboard)
  const dashboardStatus = useSelector(selectDashboardStatus)

  useEffect(() => {
    dispatch(getDashboard())
  }, [dispatch])

  const { last_test, last_topic } = dashboard

  return (
    <Box className={c.DashBoard}>
      <Grid
        item
        container
        direction='row'
        justify='space-between'
        alignItems='center'
        className={c.DashBoard_Header}
      >
        <Typography variant='h3' className={c.DashBoard_MainTitle}>
          <FormattedMessage id='shared.welcome' />
        </Typography>
      </Grid>
      <Grid item container direction='row' className={c.DashBoard_Header} md={12} xs={12}>
        <Grid item md={9} xs={12} className={c.DashBoard_CoursesWrapper}>
          <Typography variant='subtitle1' className={c.DashBoard_SubTitle}>
            <FormattedMessage id='shared.welcomeIntro' />
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item container md={12} xs={12} direction='row'>
          <Grid item md={9} xs={12} className={c.DashBoard_CoursesWrapper}>
            <CardCourses data={dashboard} status={dashboardStatus} />
          </Grid>
          <Grid item md={3} xs={12}>
            {last_test && (
              <CardWithImage
                imgUrl={last_test?.postImage}
                typeOfCard={intl.formatMessage({ id: 'shared.previousTest' })}
                title={last_test?.title}
                text={textForLastTest(dashboard)}
                buttonText={intl.formatMessage({ id: 'shared.start' })}
                progressBar={last_test?.progress}
                status={dashboardStatus}
                url={`/test/${last_test?.surroundingCourse?.id}/${last_test?.id}`}
              />
            )}
            {last_topic && (
              <CardWithImage
                imgUrl={last_topic?.postImage}
                typeOfCard={intl.formatMessage({ id: 'shared.previousTopic' })}
                title={last_topic?.title}
                text={textForLastTopic(dashboard)}
                buttonText={intl.formatMessage({ id: 'shared.view' })}
                status={dashboardStatus}
                url={`/topic/${last_topic?.surroundingCourse?.id}/${last_topic?.id}`}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DashBoard
