import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { useIntl, FormattedMessage } from 'react-intl'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  CourseProgress: {},
  CourseProgress_MainContainer: {
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
    padding: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(1),
    },
  },
  CourseProgress_Title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down(768)]: {
      textTransform: 'uppercase',
      fontSize: '14px',
      lineHeight: '19px',
      marginBottom: '10px',
      opacity: '0.87',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  CourseProgress_ProgressString: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  CourseProgress_ProgressStrings: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down(1600)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down(960)]: {
      flexDirection: 'row',
    },
  },
  CourseProgress_Text: {
    marginRight: '5px',
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.6',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
  CourseProgress_BorderLinearProgress: {
    marginBottom: theme.spacing(2),
  },
  CourseProgress_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}))

const SubCourseProgress = ({ course }) => {
  function getTimeSting(course) {
    let result = ''
    if (course) {
      const { currentHours, currentMinutes, requiredTime } = course
      if (typeof currentHours === 'number' && typeof currentMinutes === 'number') {
        result += currentHours + 'h '
        if (currentMinutes > 0) {
          result += currentMinutes + 'm '
        }
        result += '/ ' + requiredTime + 'h'
      }
    }
    return result
  }

  const c = useStyles()

  return (
    <div className={c.CourseProgress_ProgressString}>
      <Typography> {course.title}</Typography>
      <Typography>{getTimeSting(course)}</Typography>
    </div>
  )
}

const CourseProgressSummary = ({ title = '', progressData }) => {
  const c = useStyles()
  const intl = useIntl()

  return (
    <>
      <Paper className={c.CourseProgress_MainContainer}>
        <Typography className={clsx(c.CourseProgress_Title, c.CourseProgress_OneLine)}>
          {title}
        </Typography>
        <div className={c.CourseProgress_ProgressStrings}>
          {progressData.map(course => (
            <SubCourseProgress key={course.title} course={course} />
          ))}
        </div>
      </Paper>
    </>
  )
}

export default CourseProgressSummary
