import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { useParams } from 'react-router-dom'

import LayoutWithImage from '@components/molecules/LayoutWithImage'
import SideHeader from '@components/molecules/SideHeader'
import { useLocation } from 'react-router'
import IframeResizer from 'iframe-resizer-react'
import DOMPurify from 'dompurify'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { getNotepad, selectNotepad, selectNotepadStatus } from './slice'
import NavigationCard from '../../@components/molecules/NavigationCard'
import { STATUSES } from '../../@constants/slices'
import SecureFenIntegration from '@components/molecules/SecureFENIntegration'

const useStyles = makeStyles(theme => ({
  Test: {},
  Test_ButtonContainer: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  Test_Introduction: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    '& iframe': {
      maxWidth: '100%',
      height: '600px',
      [theme.breakpoints.down(1300)]: {
        height: '340px',
      },
      [theme.breakpoints.down(960)]: {
        height: '440px',
      },
      [theme.breakpoints.down(800)]: {
        height: '360px',
      },
      [theme.breakpoints.down(600)]: {
        height: '270px',
      },
      [theme.breakpoints.down(400)]: {
        height: '220px',
      },
    },
  },
  Test_Iframe: {
    minWidth: '100%',
    border: 0,
  },
  Test_IframeC: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down(960)]: {
      marginRight: '0',
      marginLeft: '0',
    },
  },
  Test_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },

    RightSide_DesktopCards: {
      display: 'block',
      [theme.breakpoints.down(960)]: {
        display: 'none',
      },
    },
  },
}))

const LeftSideTest = ({ testLink, courseTitle, courseId }) => {
  const c = useStyles()
  const intl = useIntl()

  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'iframe') {
      const sandbox = node.getAttribute('sandbox') || ''
      if (!sandbox) {
        return node.parentNode?.removeChild(node)
      }
    }
  })
  return (
    <>
      <SideHeader
        title={intl.formatMessage({ id: 'shared.notepad' })}
        type={intl.formatMessage({ id: 'shared.test' })}
        breadCrampsLinks={{
          firstLinkText: courseTitle,
          firstLinkUrl: `/course/${courseId}`,
        }}
      />
      <SecureFenIntegration src={testLink}></SecureFenIntegration>
    </>
  )
}

const Notepad = () => {
  const c = useStyles()
  const dispatch = useDispatch()
  const [isTestStarted, setIsTestStarted] = useState(false)
  const { courseId } = useParams()
  const intl = useIntl()
  const location = useLocation()
  const { courseTitle } = location.state

  useEffect(() => {
    dispatch(getNotepad(courseId))
  }, [dispatch, courseId])

  const notepad = useSelector(selectNotepad)
  const notepadStatus = useSelector(selectNotepadStatus)

  const hadleTestButton = () => {
    setIsTestStarted(prevState => !prevState)
  }
  return (
    <>
      <LayoutWithImage status={notepadStatus} postImage={notepad?.postImage}>
        <Grid item xs={12} md={9}>
          <LeftSideTest
            testLink={notepad?.testLink}
            courseTitle={courseTitle}
            courseId={courseId}
            hadleTestButton={hadleTestButton}
            isTestStarted={isTestStarted}
          />
        </Grid>
        {notepadStatus === STATUSES.LOADED && (
          <Grid item xs={12} md={3} className={c.RightSide_DesktopCards}>
            {notepad.tests.map(test => (
              <NavigationCard
                key={test.id}
                typeOfTest={intl.formatMessage({ id: 'shared.test' })}
                title={test?.title}
                text={test?.surroundingLesson?.title ? test.surroundingLesson.title : ''}
                progress={test?.progress}
                buttonText={intl.formatMessage({ id: 'shared.start' })}
                buttonLink={`/test/${courseId}/${test?.id}`}
              />
            ))}
          </Grid>
        )}
      </LayoutWithImage>
    </>
  )
}

export default Notepad
