import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectTopic, selectTopicStatus, getTopic, putProgress } from './slice'

import { useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import LayoutWithImage from '@components/molecules/LayoutWithImage'
import SideHeader from '@components/molecules/SideHeader'
import NavigationCard from '@components/molecules/NavigationCard'
import DoneButton from '@components/atoms/DoneButton'
import AccordionCard from '@components/molecules/AccordionCard'
import { FormattedMessage, useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  Topic: {},
  Topic_ButtonContainer: {
    marginTop: theme.spacing(2),
  },
  Topic_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Topic_SubTitle: {
    fontSize: '34px',
    lineHeight: '45px',
    [theme.breakpoints.down(768)]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  Topic_CountOfContent: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.6',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '19px',
    },
  },
  Topic_TestCard: {
    width: '50%',
    minHeight: '100%',
    [theme.breakpoints.down(1400)]: {
      width: '50%',
    },
    [theme.breakpoints.down(800)]: {
      width: '100%',
    },
  },
}))

const LeftSideTopic = ({ topic, handleProgress }) => {
  const { title, introduction, surroundingCourse, surroundingLesson, tests = [] } = topic
  const c = useStyles()
  const intl = useIntl()
  const amountTests = tests.length

  const getDoneBtn = () => (
    <Grid className={c.Topic_ButtonContainer} container justify='flex-end'>
      <DoneButton onClick={handleProgress} />
    </Grid>
  )

  return (
    <>
      <SideHeader
        title={title}
        introduction={introduction}
        type={intl.formatMessage({ id: 'shared.topic' })}
        mobileCards={<Cards topic={topic} />}
        doneBtn={amountTests === 0 ? getDoneBtn() : null}
        breadCrampsLinks={{
          firstLinkText: surroundingCourse?.title,
          firstLinkUrl: `/course/${surroundingCourse?.id}`,
          secondLinkText: surroundingLesson?.title,
          secondLinkUrl: `/lesson/${surroundingCourse?.id}/${surroundingLesson?.id}`,
        }}
      />
      {amountTests > 0 && (
        <>
          <Typography className={c.Topic_SubTitle} component='h3'>
            <FormattedMessage id='shared.content' />
          </Typography>
          <Typography className={c.Topic_CountOfContent} component='h3'>
            <FormattedMessage values={{ count: amountTests }} id='shared.countOfTest' />
          </Typography>
          <Grid container direction='row' spacing={2}>
            {tests.map(item => {
              return (
                <Grid item className={c.Topic_TestCard} key={item?.id}>
                  <AccordionCard data={item} courseId={surroundingCourse?.id} />
                </Grid>
              )
            })}
          </Grid>
        </>
      )}
    </>
  )
}

const Cards = ({ topic }) => {
  const { surroundingCourse, last_topic = '', next_topic = '' } = topic
  const intl = useIntl()

  return (
    <>
      {next_topic && (
        <NavigationCard
          typeOfTest={intl.formatMessage({ id: 'shared.nextTopic' })}
          title={next_topic?.title}
          text={next_topic?.surroundingLesson?.title}
          progress={next_topic?.progress}
          buttonText={intl.formatMessage({ id: 'shared.view' })}
          buttonLink={`/topic/${surroundingCourse?.id}/${next_topic?.id}`}
        />
      )}
      {last_topic && (
        <NavigationCard
          typeOfTest={intl.formatMessage({ id: 'shared.previousTopicTest' })}
          title={last_topic?.title}
          text={last_topic?.surroundingLesson?.title}
          progress={last_topic?.progress}
          buttonText={intl.formatMessage({ id: 'shared.view' })}
          buttonLink={`/topic/${surroundingCourse?.id}/${last_topic?.id}`}
        />
      )}
    </>
  )
}

const Topic = () => {
  const dispatch = useDispatch()
  const c = useStyles()
  const history = useHistory()
  const { courseId, topicId } = useParams()

  useEffect(() => {
    dispatch(getTopic(courseId, topicId))
  }, [dispatch, courseId, topicId])

  const topic = useSelector(selectTopic)
  const topicStatus = useSelector(selectTopicStatus)

  const { postImage, surroundingLesson } = topic

  const handleProgress = () => {
    dispatch(putProgress(courseId, topicId)).then(() => {
      if (!courseId) return
      const path =
        (surroundingLesson?.id && `/lesson/${courseId}/${surroundingLesson?.id}`) ||
        `/course/${courseId}`

      history.push(path)
    })
  }

  return (
    <LayoutWithImage status={topicStatus} postImage={postImage}>
      <>
        <Grid item xs={12} md={9}>
          <LeftSideTopic topic={topic} handleProgress={handleProgress} />
        </Grid>
        <Grid item xs={12} md={3} className={c.Topic_DesktopCards}>
          <Cards topic={topic} />
        </Grid>
      </>
    </LayoutWithImage>
  )
}

export default Topic
