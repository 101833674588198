import { createMuiTheme } from '@material-ui/core'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#3772ff',
      mainTransparent: '#3772ffD8',
      white: '#FFFFFF',
      green: '#43A047',
    },
    secondary: {
      main: '#6495ed',
      gray: '#707070',
      lightGray: '#C0C0C0',
    },
    background: {
      default: '#EEEEEE',
    },
    shadow: {
      default: '#00000029',
    },
    header: {
      background: 'rgba(255, 255, 255, 0.65)',
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: '#707070',
      },
    },
  },
})
