import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Pagination from '@material-ui/lab/Pagination'
import CircularProgress from '@material-ui/core/CircularProgress'

import BlockWithIcon from '../atoms/BlockWithIcon'
import { FormattedMessage } from 'react-intl'
import { STATUSES } from '@constants/slices'
import { Theme as theme } from '@material-ui/core/styles/createMuiTheme'

const useStyles = makeStyles(theme => ({
  CardCourses: {
    marginBottom: 16,
    minHeight: 214,
  },
  CardCourses_StatusContainer: {
    minHeight: 214,
  },
  CardCourses_Title: {
    padding: '16px',
    fontSize: '34px',
    lineHeight: '45px',
    paddingBottom: '24px',
    [theme.breakpoints.down(550)]: {
      fontSize: '20px',
      lineHeight: '26px',
      padding: '8px 16px',
    },
  },
  CardCourses_CardsWrapper: {
    padding: '0 16px',
    width: '100%',
  },
  CardCourses_ItemContainerWrapper: {
    width: 'calc(50% - 8px)',
    [theme.breakpoints.down(1900)]: {
      width: '100%',
    },
  },
  CardCourses_ItemContainer: {
    width: '100%',
    marginBottom: '16px',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  CardCourses_PaginationContainer: {
    marginBottom: 16,
  },
  CardCourses_FinalCourseContainer: {
    margin: '16px 16px 16px 16px',
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
  },
  CardCourses_PaginationWrapper: {
    '&>nav>ul>li>button.MuiPaginationItem-root': {
      [theme.breakpoints.down(550)]: {
        height: '26px',
        margin: '0 1px',
        padding: '0 4px',
        minWidth: '26px',
        borderRadius: '13px',
        fontSize: '14px',
      },
    },
  },
  CardCourses_HiddenBox: {
    width: '641px',
  },
  Horizontal_Divider: {
    marginLeft: '16px',
    marginRight: '16px',
    borderTop: `1px solid ${theme.palette.primary.main}`,
  },
}))

const ITEMS_ON_PAGE = 10

const getText = (lessons = []) => lessons.map(item => item?.title).join(' - ')

export default function CardCourses({ data, status }) {
  const c = useStyles()

  const { courses = [] } = data
  const coursesLength = courses.length

  const [page, setPage] = React.useState(1)
  const [countPages, setCountPages] = React.useState(0)

  useEffect(() => {
    setCountPages(Math.floor(coursesLength / ITEMS_ON_PAGE))
  }, [coursesLength])

  const handleChange = (event, value) => {
    setPage(value)
  }

  const finalCourses = courses.filter(c => !c.renderDefault)

  const ColoredLine = ({ color }) => <div className={c.Horizontal_Divider} />

  return (
    <Card className={c.CardCourses} square elevation={0}>
      {status === STATUSES.LOADING && (
        <Grid
          className={c.CardCourses_StatusContainer}
          container
          justify='center'
          alignItems='center'
        >
          <CircularProgress />
        </Grid>
      )}
      {status === STATUSES.ERROR && (
        <Grid
          className={c.CardCourses_StatusContainer}
          container
          justify='center'
          alignItems='center'
        >
          <Typography component='p'>
            <FormattedMessage id='shared.somethingWrong' />
          </Typography>
        </Grid>
      )}
      {status === STATUSES.LOADED && (
        <Box>
          <Typography className={c.CardCourses_Title}>
            <FormattedMessage id='shared.yourCourses' />
          </Typography>
          <Grid
            container
            wrap='wrap'
            justify='space-between'
            className={c.CardCourses_CardsWrapper}
          >
            {courses
              .filter(c => c.renderDefault)
              .slice(page * ITEMS_ON_PAGE - ITEMS_ON_PAGE, page * ITEMS_ON_PAGE)
              .map(course => (
                <Grid
                  item
                  key={course?.id}
                  className={c.CardCourses_ItemContainerWrapper}
                >
                  <Paper className={c.CardCourses_ItemContainer} square>
                    <BlockWithIcon
                      id={course?.id}
                      icon={course?.icon}
                      title={course?.title}
                      progress={course?.progress}
                      text={getText(course?.lessons)}
                    />
                  </Paper>
                </Grid>
              ))}
            {coursesLength % 2 === 1 && <Box className={c.CardCourses_HiddenBox}></Box>}
          </Grid>
          {courses.length > ITEMS_ON_PAGE && (
            <Grid
              className={c.CardCourses_PaginationContainer}
              container
              justify='center'
            >
              <Grid item className={c.CardCourses_PaginationWrapper}>
                <Pagination
                  count={countPages + 1}
                  page={page}
                  onChange={handleChange}
                  color='primary'
                  size='large'
                />
              </Grid>
            </Grid>
          )}
          {finalCourses.length > 0 && <ColoredLine />}
          {finalCourses.map(finalCourse => (
            <Paper
              key={finalCourse?.id}
              className={c.CardCourses_FinalCourseContainer}
              square
            >
              <BlockWithIcon
                id={finalCourse?.id}
                icon={finalCourse?.icon}
                title={finalCourse?.title}
                progress={finalCourse?.progress}
                text={getText(finalCourse?.lessons)}
              />
            </Paper>
          ))}
        </Box>
      )}
    </Card>
  )
}
