import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { selectLesson, selectLessonStatus, getLesson } from './slice'

import { useParams } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import LayoutWithImage from '@components/molecules/LayoutWithImage'
import SideHeader from '@components/molecules/SideHeader'
import NavigationCard from '@components/molecules/NavigationCard'
import CourseProgress from '@components/molecules/CourseProgress'
import AccordionCard from '@components/molecules/AccordionCard'

import { FormattedMessage, useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  Lesson: {},
  Lesson_SubTitle: {
    fontSize: '34px',
    lineHeight: '45px',
    [theme.breakpoints.down(768)]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  Lesson_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  Lesson_CountOfContent: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.6',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(768)]: {
      fontSize: '14px',
      lineHeight: '19px',
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
      marginBottom: theme.spacing(2),
    },
  },
}))

const LeftSideLesson = ({ lesson }) => {
  const intl = useIntl()
  const c = useStyles()
  const {
    title,
    introduction,
    contents = [],
    amountTopics = 0,
    amountTests = 0,
    surroundingCourse,
  } = lesson

  return (
    <>
      <SideHeader
        title={title}
        introduction={introduction}
        type={intl.formatMessage({ id: 'shared.lesson' })}
        breadCrampsLinks={{
          firstLinkText: surroundingCourse?.title,
          firstLinkUrl: `/course/${surroundingCourse?.id}`,
        }}
        mobileCards={<Cards lesson={lesson} />}
      />
      {contents.length > 0 && (
        <>
          <Typography className={c.Lesson_SubTitle} component='h3'>
            <FormattedMessage id='shared.content' />
          </Typography>
          <Typography className={c.Lesson_CountOfContent} component='h3'>
            <FormattedMessage values={{ count: amountTopics }} id='shared.countOfTopic' />
            {!!amountTopics && !!amountTests && ' - '}
            <FormattedMessage values={{ count: amountTests }} id='shared.countOfTest' />
          </Typography>
          <Grid container direction='row' spacing={2}>
            {contents.map(item => {
              return (
                <AccordionCard
                  key={item?.id}
                  data={item}
                  courseId={surroundingCourse?.id}
                />
              )
            })}
          </Grid>
        </>
      )}
    </>
  )
}

const Cards = ({ lesson }) => {
  const { progress, next_lesson = '', surroundingCourse, progressData } = lesson
  const intl = useIntl()

  const getProgressStrings = () => {
    const result = []

    if (progressData) {
      const {
        amountTests,
        amountTopics,
        amountTestsDone,
        amountTopicsDone,
      } = progressData

      if (
        typeof amountTopics === 'number' &&
        typeof amountTopicsDone === 'number' &&
        amountTopics
      ) {
        result.push(`
        ${amountTopicsDone}/${amountTopics} ${intl.formatMessage({
          id: 'shared.topics',
        })}`)
      }

      if (
        typeof amountTests === 'number' &&
        typeof amountTestsDone === 'number' &&
        amountTests
      ) {
        result.push(`
        ${amountTestsDone}/${amountTests} ${intl.formatMessage({ id: 'shared.tests' })}`)
      }
    }

    return result
  }

  return (
    <>
      <CourseProgress
        title={intl.formatMessage({ id: 'shared.progressForLection' })}
        progress={progress}
        progressStrings={getProgressStrings()}
      />
      {next_lesson && (
        <NavigationCard
          typeOfTest={intl.formatMessage({ id: 'shared.nextLesson' })}
          title={next_lesson?.title}
          text={next_lesson?.surroundingCourse?.title}
          progress={next_lesson?.progress}
          buttonText={intl.formatMessage({ id: 'shared.view' })}
          buttonLink={`/lesson/${surroundingCourse?.id}/${next_lesson?.id}`}
        />
      )}
    </>
  )
}

const Lesson = () => {
  const dispatch = useDispatch()
  const c = useStyles()
  const { courseId, lessonId } = useParams()

  useEffect(() => {
    dispatch(getLesson(courseId, lessonId))
  }, [dispatch, courseId, lessonId])

  const lesson = useSelector(selectLesson)
  const lessonStatus = useSelector(selectLessonStatus)

  return (
    <LayoutWithImage status={lessonStatus} postImage={lesson?.postImage}>
      <>
        <Grid item xs={12} md={9}>
          <LeftSideLesson lesson={lesson} />
        </Grid>
        <Grid item xs={12} md={3} className={c.Lesson_DesktopCards}>
          <Cards lesson={lesson} />
        </Grid>
      </>
    </LayoutWithImage>
  )
}

export default Lesson
