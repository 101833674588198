import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'

import { useIntl } from 'react-intl'


const useStyles = makeStyles(theme => ({
    CourseCompletionBanner: {
        padding: '12px 12px 12px 12px',
        margin: '16px 0px 16px 0px',
        backgroundColor: '#BCEBCB'
    },
    CourseCompletionHeader: {
        fontSize: '24px',
        [theme.breakpoints.down(768)]: {
            fontSize: '20px',
        },
    },
    CourseCompletionContent: {
        fontSize: '18px',
        [theme.breakpoints.down(768)]: {
            fontSize: '15px',
            lineHeight: '24px',
        },
    },
}))

const TestCompletionContent = ({ title = "" }) => {
    const intl = useIntl()
    const c = useStyles()
    return <Paper elevation={0}
        className={c.CourseCompletionBanner} >
        <Typography className={c.CourseCompletionHeader} > {intl.formatMessage({ id: 'shared.congrats' })} </Typography>
        <Typography className={c.CourseCompletionContent} > {intl.formatMessage({ id: 'shared.courseCompletion' }, { title: title })} </Typography>
    </Paper >
}

export default TestCompletionContent