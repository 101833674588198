import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import AppContext from './context/index'

import Community from '@pages/Сommunity'
import Dashboard from '@pages/Dashboard'
import MobileSearch from '@pages/MobileSearch'
import Course from '@pages/Course'
import Lesson from '@pages/Lesson'
import Test from '@pages/Test'
import Topic from '@pages/Topic'
import SSOLogin from '@pages/SSOLogin'

import Header from '@components/molecules/Header'

import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import ProfileLayout from '@components/organisms/ProfileLayout'
import Settings from '@pages/Profile/Settings'
import Stats from '@pages/Profile/Stats'
import Login from '@pages/Login'
import Notepad from '../@pages/Notepad'
import { getAuthToken } from '../@services/currentUser'
import ScrollToTop from '../@components/atoms/ScrollToTop'

const useStyles = makeStyles(theme => ({
  Content: {
    padding: theme.spacing(4),
    paddingTop: 64,
    height: '100%',
    [theme.breakpoints.down(768)]: {
      padding: theme.spacing(2),
      paddingTop: 64,
    },
  },
}))

const PrivateRoute = ({ children, ...props }) => {
  const currentUser = getAuthToken()
  return (
    <Route
      {...props}
      render={({ location }) =>
        currentUser ||
        location.pathname === '/login' ||
        location.pathname === '/token' ? (
          children
          ) : (
          <Redirect
            to={{
                pathname: '/token',
              state: { from: location },
              }}
          />
        )
      }
    />
  )
}

const App = () => {
  const c = useStyles()
  const { appState, setAppState } = useContext(AppContext)

  useEffect(() => {
    appState.isReady = true
    setAppState(appState)
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <main className={c.Content}>
        <ScrollToTop>
          <Switch>
            <Route exact path='/token'>
              <SSOLogin />
            </Route>
            <PrivateRoute exact path='/login'>
              <Login />
            </PrivateRoute>
            <PrivateRoute exact path='/dashboard'>
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute exact path='/community'>
              <Community />
            </PrivateRoute>
            <PrivateRoute exact path='/search'>
              <MobileSearch />
            </PrivateRoute>
            <PrivateRoute exact path='/course/:courseId'>
              <Course />
            </PrivateRoute>
            <PrivateRoute exact path='/lesson/:courseId/:lessonId'>
              <Lesson />
            </PrivateRoute>
            <PrivateRoute exact path='/test/:courseId/:testId'>
              <Test />
            </PrivateRoute>
            <PrivateRoute exact path='/topic/:courseId/:topicId'>
              <Topic />
            </PrivateRoute>
            <PrivateRoute exact path='/notepad/:courseId'>
              <Notepad />
            </PrivateRoute>
            <PrivateRoute exact path='/profile'>
              <ProfileLayout>
                <Settings />
              </ProfileLayout>
            </PrivateRoute>
            <PrivateRoute exact path='/statistics'>
              <ProfileLayout>
                <Stats />
              </ProfileLayout>
            </PrivateRoute>
            <Route>
              <Redirect to='token' />
            </Route>
          </Switch>
        </ScrollToTop>
      </main>
    </ThemeProvider>
  )
}
export default App
