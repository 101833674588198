import { makeStyles } from '@material-ui/styles'
import { getAuthToken } from '@services/currentUser'
import IframeResizer from 'iframe-resizer-react'
import React, { useRef } from 'react'

const useStyles = makeStyles(theme => ({
  Test_Iframe: {
    minWidth: '100%',
    border: 0,
  },
  Test_IframeC: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down(960)]: {
      marginRight: '0',
      marginLeft: '0',
    },
  },
}))

const SecureFenIntegration = ({ src }) => {
  const c = useStyles()
  const testFrame = useRef(null)

  const emitToken = () => {
    if (testFrame && testFrame.current) {
      testFrame.current.sendMessage({ type: 'Bearer', value: getAuthToken() }, '*')
    } else {
      setTimeout(emitToken, 1000)
    }
  }

  return (
    <div className={c.Test_IframeC}>
      <IframeResizer
        forwardRef={testFrame}
        heightCalculationMethod='taggedElement'
        src={src}
        className={c.Test_Iframe}
        onMessage={event => {
          if (event.message === 'FEN_REQUEST_TOKEN') {
            emitToken()
          }
        }}
      />
    </div>
  )
}

export default SecureFenIntegration
