import React, { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router'

import { clearCurrentUser, setAuthToken } from '@services/currentUser'
import Grid from '@material-ui/core/Grid'
import BackgroundImage from '@assets/login_background.png'
import { makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { logout, validateToken } from './slice'
import { getAuthToken } from '../../@services/currentUser'

const useStyles = makeStyles(theme => ({
  Login: {},
  Login_MainContainer: {
    height: '100%',
  },
  Login_BackgroundImage: {
    position: 'fixed',
    left: '0',
    minWidth: '1920px',
    maxWidth: '100%',
    zIndex: -1,
    opacity: '0.65',
    filter: 'blur(6px)',
    [theme.breakpoints.down(1200)]: {
      left: '-185px',
    },
    [theme.breakpoints.down(600)]: {
      minWidth: '1520px',
      left: '-345px',
    },
  },
}))

const extractToken = raw => {
  if (raw) return raw.substring(1, raw.length)
  else return getAuthToken()
}

const SSOLogin = () => {
  const c = useStyles({})
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const token = extractToken(location.search)
  useEffect(() => {
    if (token) {
      clearCurrentUser()
      setAuthToken(token)
      dispatch(validateToken(token))
        .then(() => {
          history.push('/dashboard')
          history.go(0)
        })
        .catch(e => {
          dispatch(logout())
        })
    } else {
      dispatch(logout())
    }
  }, [dispatch, token])
  return (
    <Grid
      className={c.Login_MainContainer}
      container
      justify='flex-start'
      alignItems='center'
    >
      <img
        className={c.Login_BackgroundImage}
        src={BackgroundImage}
        alt='leftcard background image'
      ></img>
    </Grid>
  )
}

export default SSOLogin
