import React, { useEffect, useState, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getTest, putProgress, selectTest, selectTestStatus } from './slice'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import LayoutWithImage from '@components/molecules/LayoutWithImage'
import SideHeader from '@components/molecules/SideHeader'
import NavigationCard from '@components/molecules/NavigationCard'
import DoneButton from '@components/atoms/DoneButton'
import StartButton from '@components/atoms/StartButton'
import BackButton from '@components/atoms/BackButton'

import IframeResizer from 'iframe-resizer-react'
import DOMPurify from 'dompurify'
import { FormattedMessage, useIntl } from 'react-intl'
import { getAuthToken } from '../../@services/currentUser'
import SecureFenIntegration from '@components/molecules/SecureFENIntegration'

const useStyles = makeStyles(theme => ({
  Test: {},
  Test_ButtonContainer: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
  },
  Test_Introduction: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    '& iframe': {
      maxWidth: '100%',
      height: '600px',
      [theme.breakpoints.down(1300)]: {
        height: '340px',
      },
      [theme.breakpoints.down(960)]: {
        height: '440px',
      },
      [theme.breakpoints.down(800)]: {
        height: '360px',
      },
      [theme.breakpoints.down(600)]: {
        height: '270px',
      },
      [theme.breakpoints.down(400)]: {
        height: '220px',
      },
    },
  },
  Test_DesktopCards: {
    display: 'block',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
}))

const LeftSideTest = ({ test, hadleTestButton, handleProgress, isTestStarted }) => {
  const { title, testLink, surroundingCourse, surroundingLesson, progress } = test
  const c = useStyles()
  const intl = useIntl()

  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (data.tagName === 'iframe') {
      const sandbox = node.getAttribute('sandbox') || ''
      if (!sandbox) {
        return node.parentNode?.removeChild(node)
      }
    }
  })
  return (
    <>
      <SideHeader
        title={title}
        type={intl.formatMessage({ id: 'shared.test' })}
        progress={progress}
        showProgress
        mobileCards={<Cards test={test} />}
        breadCrampsLinks={{
          firstLinkText: surroundingCourse?.title,
          firstLinkUrl: `/course/${surroundingCourse?.id}`,
          secondLinkText: surroundingLesson?.title,
          secondLinkUrl: `/lesson/${surroundingCourse?.id}/${surroundingLesson?.id}`,
        }}
      />
      <Grid className={c.Test_ButtonContainer} container justify='space-between'>
        <Grid item>
          {isTestStarted ? (
            <BackButton onClick={hadleTestButton} />
          ) : (
            <StartButton onClick={hadleTestButton} />
          )}
        </Grid>
      </Grid>
      {isTestStarted ? (
        <SecureFenIntegration src={testLink}></SecureFenIntegration>
      ) : (
        <Typography className={c.Test_Introduction} component='div'>
          <FormattedMessage id='test.introduction' />
        </Typography>
      )}
    </>
  )
}

const Cards = ({ test }) => {
  const { surroundingCourse, next_test = '', last_test = '' } = test
  const intl = useIntl()

  const textForTest = test => {
    return (
      (test?.surroundingLesson?.title ? test.surroundingLesson.title : '') +
      (test?.surroundingTopic?.title ? ` - ${test.surroundingTopic.title}` : '')
    )
  }

  return (
    <>
      {next_test && (
        <NavigationCard
          typeOfTest={intl.formatMessage({ id: 'shared.nextTest' })}
          title={next_test?.title}
          text={textForTest(next_test)}
          progress={next_test?.progress}
          buttonText={intl.formatMessage({ id: 'shared.start' })}
          buttonLink={`/test/${surroundingCourse?.id}/${next_test?.id}`}
        />
      )}
      {last_test && (
        <NavigationCard
          typeOfTest={intl.formatMessage({ id: 'shared.previousTest' })}
          title={last_test?.title}
          text={textForTest(last_test)}
          progress={last_test?.progress}
          buttonText={intl.formatMessage({ id: 'shared.start' })}
          buttonLink={`/test/${surroundingCourse?.id}/${last_test?.id}`}
        />
      )}
    </>
  )
}

const Test = () => {
  const dispatch = useDispatch()
  const c = useStyles()
  const history = useHistory()
  const { courseId, testId } = useParams()

  const [isTestStarted, setIsTestStarted] = useState(false)

  useEffect(() => {
    dispatch(getTest(courseId, testId))
  }, [dispatch, courseId, testId])

  const test = useSelector(selectTest)
  const testStatus = useSelector(selectTestStatus)

  const { surroundingTopic, surroundingLesson } = test

  const hadleTestButton = () => {
    setIsTestStarted(prevState => !prevState)
  }

  const handleProgress = () => {
    dispatch(putProgress(courseId, testId)).then(() => {
      if (!courseId) return
      const path =
        (surroundingTopic?.id && `/topic/${courseId}/${surroundingTopic?.id}`) ||
        (surroundingLesson?.id && `/lesson/${courseId}/${surroundingLesson?.id}`) ||
        `/course/${courseId}`

      history.push(path)
    })
  }

  return (
    <>
      <LayoutWithImage status={testStatus} postImage={test?.postImage}>
        <Grid item xs={12} md={9}>
          <LeftSideTest
            test={test}
            hadleTestButton={hadleTestButton}
            handleProgress={handleProgress}
            isTestStarted={isTestStarted}
          />
        </Grid>
        <Grid item xs={12} md={3} className={c.Test_DesktopCards}>
          <Cards test={test} />
        </Grid>
      </LayoutWithImage>
    </>
  )
}

export default Test
