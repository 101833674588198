import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Avatar from 'react-avatar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CardActionArea from '@material-ui/core/CardActionArea'

import { useHistory } from 'react-router-dom'

import { useIntl, FormattedMessage } from 'react-intl'

import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  SearchItem_MainContainer: {
    maxWidth: '690px',
    backgroundColor: '#fff',
    padding: 0,
    color: '#000000',
    [theme.breakpoints.down(960)]: {
      maxWidth: '100%',
    },
  },
  SearchItem_MainContainer__NotFound: {
    textAlign: 'center',
    height: 'auto',
    width: '220px',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down(960)]: { width: '100%' },
  },
  SearchItem_Icon: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    [theme.breakpoints.down(768)]: {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
  },
  SearchItem_TitleWrapper__Centered: {
    width: '100%',
  },
  SearchItem_Title: {
    fontSize: '24px',
    lineHeight: '32px',
    maxWidth: '580px',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(960)]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down(768)]: {
      fontSize: '16px',
      lineHeight: '22px',
      marginTop: theme.spacing(1),
    },
  },
  SearchItem_TypeOfCard: {
    fontSize: '14px',
    lineHeight: '19px',
    opacity: '0.87',
    textTransform: 'uppercase',
    [theme.breakpoints.down(768)]: {
      fontSize: '12px',
    },
  },
  SearchItem_ButtonContainer: {
    width: '100%',
    textAlign: 'left',
  },
  SearchItem__OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down(960)]: {
      whiteSpace: 'normal',
    },
  },
  SearchItem__Centered: {
    width: '100%',
    height: '100%',
    paddingTop: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  SearchItem_CourseName: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.6',
    marginBottom: '6px',
    [theme.breakpoints.down(768)]: {
      fontSize: '12px',
    },
  },
  SearchItem_Button: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: theme.spacing(2),
    textTransform: 'uppercase',
    [theme.breakpoints.down(768)]: {
      fontSize: '12px',
    },
  },
  SearchItem_Divider: {
    margin: '0 16px',
  },
  SearchItem_GridContainer: {
    padding: '16px 16px 0 16px',
  },
  Search_LeftSideContainer: {
    paddingLeft: 16,
  },
}))

const SearchItem = ({
  isCourse = false,
  idCourse = '',
  courseName = '',
  title = '',
  iconSrc = '',
  divider = false,
  id = 0,
  specialResult,
  onBackdropSet,
  onSearchValueSet,
  isMobile,
}) => {
  const c = useStyles()
  const history = useHistory()
  const intl = useIntl()

  const handleItemButton = () => {
    onSearchValueSet('')
    onBackdropSet(false)
    isCourse ? history.push(`/course/${id}`) : history.push(`/lesson/${idCourse}/${id}`)
  }

  const isBlock = typeof specialResult !== 'string'

  return specialResult ? (
    <Box
      className={clsx(c.SearchItem_MainContainer, c.SearchItem_MainContainer__NotFound)}
    >
      <Grid container wrap='nowrap'>
        <Grid
          item
          lg={12}
          className={clsx(isBlock && c.SearchItem_TitleWrapper__Centered)}
        >
          <Typography
            className={clsx(
              c.SearchItem_Title,
              c.SearchItem__OneLine,
              isBlock && c.SearchItem__Centered
            )}
            component={isBlock ? 'div' : 'p'}
          >
            {specialResult}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box className={c.SearchItem_MainContainer}>
      <CardActionArea onClick={handleItemButton}>
        <Grid container wrap='nowrap' className={c.SearchItem_GridContainer}>
          {isCourse && (
            <Grid className={c.IconContainer} item>
              <Avatar
                className={c.SearchItem_Icon}
                src={iconSrc}
                color={'#BB2424'}
                size={isMobile ? 50 : 64}
                round={true}
                title={title}
                name={title.replace(/[^a-zA-Z\s]+/g, '')}
                maxInitials={2}
              />
            </Grid>
          )}
          <Grid item className={c.Search_LeftSideContainer}>
            <Typography className={c.SearchItem_TypeOfCard} component='p'>
              {isCourse
                ? intl.formatMessage({ id: 'shared.course' })
                : intl.formatMessage({ id: 'shared.lesson' })}
            </Typography>
            <Typography
              className={clsx(c.SearchItem_Title, c.SearchItem__OneLine)}
              component='p'
            >
              {title}
            </Typography>
            {!isCourse && (
              <Typography className={c.SearchItem_CourseName}>{courseName}</Typography>
            )}
            <Grid className={c.SearchItem_ButtonContainer} item>
              <Typography className={c.SearchItem_Button} color='primary'>
                <FormattedMessage id='shared.view' />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {divider && <Divider className={c.SearchItem_Divider} />}
      </CardActionArea>
    </Box>
  )
}

export default SearchItem
